<template>
   <div class="background-willys-pink holding-page-container">
      <div class="logo-container">
        <img src="/assets/images/willys-logo.svg" alt="Willys Logo">
      </div>
      <div class="form-container">
        <div class="coming-soon">
          <h2 class="coming-soon-text">COMING SOON</h2>
          <img class="coming-soon-image" src="/assets/images/coming-soon-logo.png" alt="Coming Soon">
          <form method="post" data-netlify="true" name="contact">
            <div class="input-group">
              <input name="meal" type="text" placeholder="Favourite meal?" class="text-input" required />
              <input name="name" type="text" placeholder="Name" class="text-input" required />
              <input name="email" type="email" placeholder="Email address" class="text-input" required />
              <button type="submit" class="send-button">Send</button>
            </div>
          </form>
        </div>
      </div>
      <div class="instagram-logo">
        <a href="https://www.instagram.com/margatehousehotel" target="_blank">
          <img width="45px" height="45px" src="/assets/images/instagram.svg" alt="Instagram Logo">
        </a>
      </div>
    </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
</style>
